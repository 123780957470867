import React from 'react';
import { LinePrice } from '@kerfed/client';

import CircularLoading from '../../CircularLoading';
import { money_format } from '@kerfed/common/utils';
import OperationList from '../../OperationList';

interface ItemRowPriceProps {
  loading?: boolean;
  enabled?: boolean;
  linePrice?: LinePrice | null;
}

const ItemRowPrice = ({
  loading = false,
  enabled = false,
  linePrice,
}: ItemRowPriceProps) => {
  if (!enabled || linePrice?.quantity === 0) {
    return <></>;
  }

  // format the total line item price
  const total = money_format(linePrice?.total);

  // add a unit price for helpful notes.
  const unit =
    linePrice?.total?.amount &&
    (linePrice?.quantity || 0) > 0 &&
    linePrice?.total?.amount / (linePrice?.quantity || 1);

  // add either a unit price summary or the error message.
  const message = total
    ? `${money_format({ currency: linePrice?.total?.currency, amount: unit || 0 })} each`
    : linePrice?.outcome?.errors?.[0]?.message;

  return (
    <>
      <td>
        {!!loading ? (
          <CircularLoading />
        ) : (
          <>
            <h2>{total || 'No Quote'}</h2>
            <p>{message}</p>
            <OperationList data={linePrice?.operations || { operations: [] }} />
          </>
        )}
      </td>
    </>
  );
};

export default ItemRowPrice;
