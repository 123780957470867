import React from 'react';
import { Outcome } from '@kerfed/client';
import { TScene } from '@kerfed/types';

import FileUploader, { TPathWithUnit } from '../FileUploader';
import File from '../File';

import { getUUIDFromFileId } from '@kerfed/common/utils';

interface FileListProps {
  models?: TScene[];
  isProcessing?: boolean;
  onAddOrderFile?: (files: TPathWithUnit[]) => Promise<void>;
}

const FileList = ({
  models = [],
  isProcessing = false,
  onAddOrderFile,
}: FileListProps) => {
  return (
    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 g-lg-3">
      {models.map((model, index) => {
        const outcome: Outcome = (model as any)?.outcome;
        const errorMessage = outcome?.errors
          ?.map((e) => e.message || '')
          .join('\n');
        return (
          <div className="col p-2" key={index}>
            <File
              fileId={getUUIDFromFileId(model?.original?.file_id || '')}
              fileName={model?.original?.name || ''}
              fileUrl={model?.preview?.url || ''}
              isError={!!outcome?.is_completed && !outcome.is_success}
              errorMessage={errorMessage}
            />
          </div>
        );
      })}

      {isProcessing ? (
        <div className="col p-2">
          <File loading={true} />
        </div>
      ) : (
        <div className="col p-2">
          <div className="card dropzone h-100 d-flex justify-content-center">
            <FileUploader onUploads={onAddOrderFile} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileList;
