import React, { useMemo } from 'react';
import { PartConfiguration, BentFabrication } from '@kerfed/client';

import FileImage from 'app/src/components/FileImage';
import EditablePartConfiguration from '../EditablePartConfiguration';

import { EditablePartProps } from './types';
import { formatExtents } from '@kerfed/common/utils';

interface EditableBentPartProps extends EditablePartProps {
  data: BentFabrication;
}

const EditableBentPart = ({
  partId,
  data,
  shop,
  config,
  onChangePartOrderData,
}: EditableBentPartProps) => {
  const strExtents = useMemo(() => {
    return formatExtents(
      data?.flat?.extents || [],
      shop?.brand?.display_units || undefined,
    );
  }, [data?.flat?.extents]);

  const handleChangePartOrderData = (newConfig: PartConfiguration) => {
    onChangePartOrderData(partId, newConfig);
  };

  return (
    <div>
      <div className="grid">
        <div className="row">
          <div className="col-md">
            {!!strExtents && <span className="fw-bold">{strExtents}</span>}

            <EditablePartConfiguration
              posts={data.posts}
              shop={shop}
              defaultConfig={data.defaults}
              config={config || data.defaults}
              onChangePartOrderData={handleChangePartOrderData}
            />
          </div>
          <div className="col-md">
            {data?.drawing?.url && <FileImage url={data.drawing.url} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditableBentPart;
