import { money_format, titleCase } from '@kerfed/common/utils';
import { micromark } from 'micromark';
import React from 'react';

interface Price {
  currency: string;
  amount: number;
}

interface Stock {
  material_id: string;
  supplier_id: string;
  supplier_pin: string;
  supplier_link: string;
  price: Price;
  quantity: number;
  kind: string;
  extents: number[];
  units: string;
  stock_id: string;
}

interface Operation {
  operation_id: string;
  kind: string;
  stock?: Stock;
  price: Price;
  note: string;
  shop_id: string;
  address_id: string;
  files: any[];
}

const OperationList: React.FC<{ data: Operation[] }> = ({ data }) => {
  return (
    <ul className="operation  list-group list-group-numbered">
      {data.map((operation) => (
        <li
          key={operation.operation_id}
          className="list-group-item d-flex justify-content-between align-items-start"
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">{operation.kind}</div>
            <p>Subtotal: {money_format(operation.price)}</p>
            <div
              dangerouslySetInnerHTML={{ __html: micromark(operation.note) }}
            />
            {operation.stock && (
              <div>
                <h4>Stock Information</h4>
                <p>
                  <i>{operation.stock.material_id}</i>
                </p>
                <a target="_blank" href={operation.stock.supplier_link}>
                  {`${titleCase(operation.stock.supplier_id)} ${operation.stock.supplier_pin}`}
                </a>
                <br />
                Unit Price: {money_format(operation.stock.price)}
                <p>Quantity: {operation.stock.quantity}</p>
                <p>Kind: {operation.stock.kind}</p>
                <p>Extents: {operation.stock.extents.join(' x ')}</p>
                <p>Units: {operation.stock.units}</p>
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default OperationList;
